import { Company } from './company.class';

export class User {
  id: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  roles: UserRoles[] = [];
  password: string = '';
  companies: Company[] = [];
}

export enum UserRoles {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  PAID_CAMPAIGNS = 'PAID_CAMPAIGNS'
}
