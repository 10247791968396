import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtils } from '../utils/http-utils';
import { Company } from '../models/company.class';
import { UserRoles } from '../models/user.class';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private triggerGetUserAndSetCompanies = new Subject<void>(); // Subject specifically for triggering the getUserAndSetCompanies function inside main.component
  public getUserAndSetCompanies$ = this.triggerGetUserAndSetCompanies.asObservable(); // Observable that main.component can subscribe to

  constructor(private httpClient: HttpClient) {}

  login(username: string, password: string) {
    return this.httpClient
      .post<any>(`${environment.serviceUrl}/login`, { username, password })
      .pipe(
        tap((user) => {
          this.setToken(user.accessToken);
          this.setUser(user);
          if (user.roles.includes(UserRoles.ADMIN) && user.companies[0]) {
            this.setSelectedCompany(user.companies[0].id);
            this.setSelectedCompanyName(user.companies[0].name);
          }
        })
      );
  }

  getUser(): any {
    const userJson = localStorage.getItem('user');
    if (userJson !== null) {
      try {
        return JSON.parse(userJson);
      } catch (e) {
        console.error('Error parsing user data from localStorage', e);
        return null;
      }
    }
    return null;
  }

  setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      console.log('Clearing access_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('selected_company');
      localStorage.removeItem('selected_company_name');
      observer.complete();
    });
  }

  isUserLoggedIn() {
    return !!localStorage.getItem('access_token');
  }

  me() {
    return this.httpClient.get(`${environment.serviceUrl}/users/me`);
  }

  getCurrentUserCompany() {
    return this.httpClient.get(`${environment.serviceUrl}/companies/me`);
  }

  updateCurrentUserCompany(payload: Company) {
    return this.httpClient.put(
      `${environment.serviceUrl}/companies`,
      payload
    );
  }

  updateProfile(updateProfilePayload: { firstName: string; lastName: string }) {
    return this.httpClient.put(
      `${environment.serviceUrl}/users/me`,
      updateProfilePayload
    );
  }

  changePassword(changePasswordPayload: {
    oldPassword: string;
    newPassword: string;
    repeatNewPassword: string;
  }) {
    return this.httpClient.put(
      `${environment.serviceUrl}/users/change-password`,
      changePasswordPayload
    );
  }

  forgotPassword(forgotPasswordReq: any) {
    return this.httpClient.post<any>(
      `${environment.serviceUrl}/users/forgot-password`,
      {},
      {
        params: HttpUtils.getHttpParams(forgotPasswordReq),
      }
    );
  }

  resetPassword(resetPasswordReq: any) {
    return this.httpClient.post<any>(
      `${environment.serviceUrl}/users/reset-password`,
      resetPasswordReq
    );
  }

  emitGetUserAndSetCompaniesCall() {
    this.triggerGetUserAndSetCompanies.next();
  }

  // logout(force = false) {
  //   if (force) {
  //     localStorage.removeItem('access_token');
  //   }
  //   return this.httpClient.post(`${environment.serviceUrl}/logout`, {}).pipe(tap(response => {
  //     localStorage.removeItem('access_token');
  //   }));
  // }

  // getCurrentUser(): Observable<UserModel> {
  //   return this.httpClient.get<UserModel>(`${environment.serviceUrl}/me`);
  // }

  getToken() {
    return localStorage.getItem('access_token');
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  setSelectedCompany(company: string) {
    localStorage.setItem('selected_company', company);
  }

  setSelectedCompanyName(company: string) {
    localStorage.setItem('selected_company_name', company);
  }

  getSelectedCompany(): string {
    return localStorage.getItem('selected_company') || '';
  }

  getSelectedCompanyName(): string {
    return localStorage.getItem('selected_company_name') || '';
  }
}
